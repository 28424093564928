import React from 'react'
import styled from 'styled-components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const BreadcrumbContainer = styled.div`
  & ol {
    padding: 0;
    margin: 0;
    display: flex;
  }

  & li {
    list-style: none;
  }

  & a {
    display: block;
    font-size: 16px;
    line-height: 21px;
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    &[aria-current='page'] {
      color: ${({ theme }) => theme.color.secondary};
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    &:first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  .breadcrumb__separator {
    margin: -5px 8px;
    font-size: 20px;
    color: ${({ theme }) => theme.color.light};
  }

  .breadcrumb__list {
    display: flex;
    flex-wrap: wrap;
  }
`

interface BreadcrumbProps {
  pageContext?: any
  location?: any
  label?: string
  className?: string
}

const BreadcrumbProvider: React.FC<BreadcrumbProps> = ({
  pageContext,
  location,
  label,
  className = '',
}) => {
  if (!location.pathname.includes('preview')) {
    const {
      breadcrumb: { crumbs },
    } = pageContext

    crumbs.forEach((element: any) => {
      // eslint-disable-next-line no-param-reassign
      element.crumbLabel = element.crumbLabel.replace(/-/g, ' ')
    })

    return (
      <BreadcrumbContainer className="mt-3">
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" > "
          crumbLabel={label}
          className={className}
        />
      </BreadcrumbContainer>
    )
  }

  return (
    <BreadcrumbContainer className="mt-3">
      <span className="text-white">
        Kruimelpad niet beschikbaar in preview.
      </span>
    </BreadcrumbContainer>
  )
}

export default BreadcrumbProvider
