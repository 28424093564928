import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BreadcrumbProvider from 'components/elements/Misc/Breadcrumb'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const StyledBannerDefault = styled.section`
  display: flex;
  justify-content: center;
`

const BannerWrapper = styled.div`
  position: relative;
  height: min-content;
  max-width: 2000px;
  width: 100%;
  background: linear-gradient(90deg, #262626 3.82%, rgba(38, 38, 38, 0) 79.1%);
  padding: 240px 0 150px 0;

  @media (max-width: 1199px) {
    padding: 180px 0 60px 0;
  }

  @media (max-width: 991px) {
    padding: 200px 0 60px 0;
  }

  @media (max-width: 575px) {
    padding: 180px 0 15px 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1 {
    color: ${({ theme }) => theme.color.light};
  }

  @media (max-width: 991px) {
    & h1 {
      & span {
        line-height: 30px;
      }
    }
  }
`

const BannerDefault: React.FC<BannerProps> = ({
  fields,
  pageContext,
  location,
}) => (
  <StyledBannerDefault>
    <BannerWrapper>
      <StyledPlaatjie alt="banner" image={fields?.image} />
      <div className="container py-lg-5 py-3">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <Content content={fields.description} />
            <BreadcrumbProvider pageContext={pageContext} location={location} />
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
