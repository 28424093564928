import React from 'react'

// Third party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Chevron from 'img/chevrondown.inline.svg'
import NavLink from 'components/shared/NavLink'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const StyledBannerLanding = styled.section`
  display: flex;
  justify-content: center;
`

const BannerWrapper = styled.div`
  position: relative;
  height: 100vh;
  max-width: 2000px;
  width: 100%;
  background: linear-gradient(90deg, #262626 3.82%, rgba(38, 38, 38, 0) 79.1%);
  padding: 250px 0;
  max-height: 920px;

  @media (max-width: 1199px) {
    padding: 250px 0 200px 0;
  }

  @media (max-width: 991px) {
    padding: 180px 0 100px 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1 {
    font-weight: ${({ theme }) => theme.font.weight.extraLight} !important;
  }

  & h2 {
    font-size: ${({ theme }) => theme.font.size.large};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  @media (max-width: 350px) {
    & h1 {
      font-size: 24px;
    }
  }
`

const SvgWrapper = styled(motion.div)`
  position: absolute;
  left: calc(50% - 10px);
  bottom: 30px;
`

const BannerLanding: React.FC<BannerProps> = ({ fields }) => {
  const bounceTransition = {
    y: {
      yoyo: Infinity,
    },
  }
  return (
    <StyledBannerLanding>
      <BannerWrapper>
        <StyledPlaatjie alt="banner" image={fields?.image} />
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <Content content={fields.description || ''} />
            </div>
          </div>
        </div>
        <SvgWrapper animate={{ y: [10, 0] }} transition={bounceTransition}>
          <NavLink
            to={fields.scrollto?.url || '/#usps'}
            className="text-hidden"
          >
            {fields.scrollto?.title}
            <Chevron />
          </NavLink>
        </SvgWrapper>
      </BannerWrapper>
    </StyledBannerLanding>
  )
}

export default BannerLanding
